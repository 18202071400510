main.user-dashboard-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1.title-text, h2.title-text {
  text-align: center;
  margin-top:2rem;
}

p.p-text {
  font-size: 2rem;
  margin-top: 3rem;
  text-align:center;
}
article.stats-container {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 10rem));
  gap: 3rem;
  margin: 1rem;
}

div.individual-stat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgb(0 0 0 / 6%);
}

div.individual-stat-container:hover {
  transform: scale(1.03);
  transition: 0.2s;
}

div.wannago-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(336.99px, 1fr));
  margin-block: 1rem;
  max-width: 80%;
  margin: 0 auto; 
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 2rem;
  justify-items: center;
}

@media (max-width: 474px) {
 div.wannago-cards-container {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  margin-block: 1rem;
  margin-bottom: 6rem;
}
}
