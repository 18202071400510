div.form-container {
  height: calc(100dvh - var(--height-top-navbar)- var(--height-bottom-navbar));
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}

