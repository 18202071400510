.row {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.option-container {
 display: flex;
 flex-direction: column;
 place-items: center;
 align-items: center;
 justify-content: center;
 width: 337px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 337px;
}