aside.sidebar-info {
  height: 100%; 
  width: 23.5rem; 
  position: fixed; 
  z-index: 1; 
  top: 4rem; 
  left: 0;
  overflow-x: hidden; 
  padding-top: 2rem;
  padding-inline: 1rem;
}

p.seen-by {
  font-size: 2rem;
  text-align: center;
}

aside.sidebar-info::after {
  content: "";
  position: absolute;
  top: 5%; 
  bottom: 14%; 
  right: 0;
  width: 1px; 
  background-color: var(--clr-secondary); 
}

section.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 23.5rem;
  margin-top: 2rem;
  overflow: auto;
}

article.stats-container {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  justify-items: center;
  grid-gap: 1rem;
  max-width: 45rem;
  width: 100%;
  margin-bottom: 5rem;
}

div.counters-box-info {
  border: 1px solid var(--clr-secondary);
  border-radius: 10px;
  padding-inline: 0.5rem;
  width: 180px;
  height: 160px;
  position: relative;
}

h6.counter-description {
  margin-bottom: 0;
  text-align: center;
  padding-top: 1rem;
}
p.counter {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 4rem;
  font-weight: 500;
  color: var(--clr-secondary);
  margin-bottom: 0;
  text-align: center;
}


article.detail-info-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 45rem;
  width: 100%;
  margin-top: 2rem;
}

div.people-going-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

h1.titles {
  text-decoration: underline;
}

p.people-going {
  font-size: 1.2rem;
  border-radius: 8px;
  padding: 0px 10px;
  background-color: var(--clr-primary);
  width: 20rem;
}

div.suggestions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

div.message {
  border: 1px solid var(--clr-secondary);
  border-radius: 10px;
  font-size: larger;
  padding: 0px 10px;
  width: 20rem;
   overflow-wrap: break-word;
}

@media (max-width: 1100px) {
  main.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 10rem; */
  }

  aside.sidebar-info {
    height: auto; 
    width: 100%; 
    position: static; 
    z-index: auto; 
    top: auto; 
    left: auto;
    overflow-x: visible; 
    padding-top: 0;
    padding-inline: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto; 
  }

  section.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  height: auto;
  margin-bottom: 6rem;
  overflow-y: auto; 
}

aside.sidebar-info::after {
  display: none;
}
}




