.created-wannago {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  gap: 1rem;
}

.user-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 337px;
  margin: 2rem 2rem 0rem 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 6%);
}

