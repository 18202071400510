:root {
  --clr-primary: rgb(242, 205, 211);
  --clr-secondary: rgb(241, 138, 203); 
  --height-top-navbar: 5rem;
  --height-bottom-navbar: 5rem;
}

@font-face {
  font-family: Kanit;
  src: url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
}

@font-face {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 700;
  src: url('../../client/public/MontserratAlternates-SemiBoldItalic.woff2')
    format('truetype');
  font-display: swap;
}

.logo {
  font-family: 'Montserrat Alternates', sans-serif;
  color: var(--clr-primary);
   text-shadow: 2px 2px 2px var(--clr-primary);
  -webkit-text-stroke: 1px var(--clr-secondary);
  font-size: 2rem;
  margin:0;
}

.top-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.bottom-navbar {
  padding: 1rem;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  min-width: 50%;
  max-width: 50%;
}



.button {
  margin-right: 20px;
  background-color: var(--clr-primary);
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  box-shadow: 2px 2px var(--clr-secondary);
}

.button:hover {
  cursor: pointer;
  background-color: var(--clr-secondary);
  box-shadow: 2px 2px var(--clr-primary);
}

.button:active {
  background-color: var(--clr-secondary);
  box-shadow: 2px 2px var(--clr-primary);
  box-shadow: none;
  transform: translateY(2px);
  transform: translateX(2px);
}

.highlight {
  width: fit-content;
  border: 3px dashed var(--clr-secondary);
  border-radius: 5px;
  margin-top: 5px;
  font-size: 20px;
}

.maybeTextArea {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}


.buttonDelete {
  display: flex;
  justify-content: center;
  align-items: center;
}


.button.maybe {
  margin-bottom: 30px;
}



@media (width <= 767px) {
  .bottom-navbar {
    display: block;
  }

   .main-container {
    padding-bottom: var(--height-bottom-navbar); /* Adjust as needed to account for the height of the navbar */
  }
  .top-navbar {
    display: none;
  }
}

@media (768px <= width) {
  .top-navbar {
    display: block;
  }

  .main-container {
    padding-top: var(--height-top-navbar); /* Adjust as needed to account for the height of the navbar */
  }

  .bottom-navbar {
    display: none;
  }
}

