.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-buttons {
  width: fill;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.copy-button {
  background-color: var(--clr-primary);
  border-radius: 5px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 2px 2px var(--clr-secondary);
}

