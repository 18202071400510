.page-container {
    display: grid;
    place-content: center;
    gap: 1rem;
    height: calc(100vh - var(--height-top-navbar));
    overflow-y: auto;
    padding-bottom: var(--height-bottom-navbar); 
}

h2 {
  text-align: center;
}

.option-button {
  background-color: var(--clr-primary);
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 0.5rem 0.5rem;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 3px 2px var(--clr-secondary);
  margin-bottom: 1rem;
}

.option-button:hover {
  cursor: pointer;
  background-color: var(--clr-secondary);
  box-shadow: 3px 2px  var(--clr-primary);
}

.option-button.important {
  background-color: var(--clr-secondary);
  box-shadow: 3px 2px  var(--clr-primary);
}

.option-button.important:hover {
  cursor: pointer;
  background-color:  var(--clr-primary);
  box-shadow: 3px 2px var(--clr-secondary);
}

.option-button:active {
  background-color:  var(--clr-secondary);
  box-shadow: 3px 2px  var(--clr-primary);
  transform: translateY(2px);
  transform: translateX(2px);
}
.buttons {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  gap: 1rem;
}

.form {
  display: flex;
  justify-content:center;
  gap: 10px;
}

.btn {
  display: flex;
  align-items: flex-start;
}