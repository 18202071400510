.signup-button {
  width: 100%;
  background-color: var(--clr-primary);
  margin-top: 1rem;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
}

.line-after-auth-card {
  text-align: center;
  margin-top: 16px;
}

.line-after-auth-card.forgot {
  text-align: center;
  margin-top: 32px;
}

.btns-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.button.cancel {
  background-color: var(--clr-secondary);
  box-shadow: 2px 2px var(--clr-primary);

}

