.card-container {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 6%);
  width: 337px;
  height: 265px;
  transition-duration: 0.2s;
  position: relative;
}

.card-container:hover {
  transform: scale(1.03);
  transition: 0.2s;
  box-shadow: 0 4px 6px rgb(0 0 0 / 6%);
}

.date-aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-primary);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 25%;
  color: white;
}

.info-and-options-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-left: 2%;
}

.info-and-options-container-simple {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding: 2%;
  justify-content: space-around;
  height: 100%;
}

.info-container {
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: space-around;
  height: 80%;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 2.1rem;
}

.info {
  color: #a9978c;
}

.description-simple {
  display: flex;
  flex-direction: column;
  color: #a9978c;
}

.text {
  font-size: 1.1rem;
  margin: 0;
}

.options-container {
  position: absolute;
  width: 250px;
  bottom: 0;
}

.options {
  display: flex;
  justify-content: space-around;
  height: 10%;
  border-top: 4px dotted var(--clr-primary);;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-right: 2%;
}

.clickable:hover {
  cursor: pointer;
  color: var(--clr-secondary);
}

.share-modal {
  background-color: white;
  position: absolute;
  width: 240px;
  height: 90px;
  bottom: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 6%);
}

.delete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
