.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border-bottom: 1px solid var(--clr-secondary);
  box-shadow: 0 1px 0px 0px var(--clr-primary);
  height: var(--height-top-navbar);
  position: sticky;
}

.user-sign-in-buttons {
  display: flex;
  gap: 10px;
}

.landing-page-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 4rem;
}

.Logo {
  width: 15rem;
  /* margin-left: 6.5rem; */
}

.sign-up-button {
  padding: 0.5rem 2rem;
  background-color: white;
  border-radius: 500px;
  border: 2px solid var(--clr-secondary);
  color: var(--clr-secondary);
  font-weight: bold;
  font-size: 1.5rem;
}

.sign-up-button:hover {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
}

.sign-up-button:active {
  transform: translateY(2px);
  transform: translateX(2px);
}

.log-in-button {
  padding: 0.5rem 2rem;
  background-color: var(--clr-primary);
  border-radius: 500px;
  border: 2px solid var(--clr-primary);
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.log-out-button {
  padding-left: 1rem;
  background-color: var(--clr-primary);
  border-radius: 500px;
  border: 2px solid var(--clr-primary);
  color: white;
}

.log-out-button:hover {
  background-color: var(--clr-secondary);
  border: 2px solid var(--clr-secondary);
}

.log-out-button:active {
  transform: translateY(2px);
  transform: translateX(2px);
}
.log-in-button:active {
  transform: translateY(2px);
  transform: translateX(2px);
}

.log-in-button:hover {
  background-color: var(--clr-secondary);
  border: 2px solid var(--clr-secondary);
}

.create-wannago-button {
  background-color: var(--clr-primary);
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 2px 2px var(--clr-secondary);
  /* margin-right:10rem; */
}

.create-wannago-button:hover {
  background-color: var(--clr-secondary);
  box-shadow: 2px 2px var(--clr-primary);
}

.create-wannago-button:active {
  background-color: var(--clr-secondary);
  box-shadow: 2px 2px var(--clr-primary);
  box-shadow: none;
  transform: translateY(2px);
  transform: translateX(2px);
}

/*NavBar Bottom*/

.nav-container-bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid var(--clr-secondary);
  height: 3.5rem;
  padding-top: 1rem;
}

